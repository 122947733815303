<template>
    <div id="home">
        <van-row type="flex" justify="center" class="content searchContent">
            <van-col span="24" ref="content" style="height:100%;padding-bottom:40px;">
                <van-list class="productList" v-model="loading" :finished="finished" finished-text="没有更多了" @load="productLoad" :immediate-check="false">
                    <masonry :cols="4" :gutter="10" class="waterfall03">
                        <div v-for="(item, index) in productList" :key="index" @click="jump(item.id)" class="listBox">
                            <img v-lazy="item.thumbnail" />
                            <div class="introduce">
                                <div class="item-name">
                                    <h4>{{item.name}}</h4>
                                    <p>
                                        <i class="iconfont  icon-chakan"></i><span>{{item.browseCount}}</span>
                                        <i class="iconfont icon-xingxing"></i><span>{{item.collectionCount}}</span>
                                    </p>
                                </div>
                                <div class="item-footer">
                                    <i v-if="item.favFlag" class="iconfont icon-shoucang1" @click.stop="cancelCollection(item.id, index)"></i>
                                    <i  v-else class="iconfont icon-xin3 collection" @click.stop="onChangeCollection(item.id, index)"></i>
                                </div>
                            </div>
                        </div>
                    </masonry>

                </van-list>
            </van-col>
        </van-row>

        <van-popup v-model="collectionModal" class="addCollection">
            <AddCollection ref="collection" :productId="productId" :productIndex="productIndex" @confirmCollection="confirmCollection"/>
            <div class="cancelTips">点击空白处--“取消收藏”</div>
        </van-popup>

        <van-popup v-model="screenModal" :position="position == 1 ? 'right' : 'left'" :class="position == 1 ? 'screenModal mr' : 'screenModal ml'">
            <div class="header">高级筛选</div>
            <div class="content">
                <div v-for="(item, ulIndex) in screenData" :key="ulIndex">
                    <h4>{{item.name}}</h4>
                    <ul>
                        <li v-for="(item, index) in item.labels" :key="index" @click="selectScreen($event, ulIndex)">{{item.name}}</li>
                    </ul>
                </div>
            </div>
            <div class="footer">
                <button @click="confirmScreen">确认</button>
                <button @click="screenModal = false">取消</button>
            </div>
        </van-popup>

        <rotate-square5 v-if="isLoading" class="loading"></rotate-square5>
    </div>
</template>

<script>
import {getBanner, getTypes, getProduct, removeFav} from '../api/index'
import AddCollection from './addCollection'
import {RotateSquare5} from 'vue-loading-spinner'
export default {
    data () {
        return {
            token: '',
            position: 1,  //1右 2左
            bannerList: [],
            loading:false,
            finished: false,
            isLoading:false, //控制加载动画
            activeKey: 0, //导航定位
            typesList: '', //导航列表
            productData: { //获取产品入参
                token: '',
                typeId: 0, //0表示推荐产品,
                labels: '',
                key: '', //产品名称检索
                index: 1, //分页 
            },
            productList: '', //产品列表
            loadingDotStyle: { //loading动画内小圆点的样式对象，自定义其样式
                background: '#3CA8FE',
            },
            collectionModal: false,
            productId: '',
            productIndex: '',
            screenModal: false, //筛选弹窗
            screenData:'', //筛选数据
            screenLabels:[], //筛选条件
            col:5,
            isLoaded: false,
            screenIsShow: false, //控制筛选按钮出现
        }
    },
    mounted () {
    },
    activated () {
        const {token, position, key} = this.$route.query;
        this.token = token;
        this.position = parseInt(position);
        key && this.product(key);
        const scrollTop = this.$route.meta.scrollTop;
        const $content = document.querySelector('.productList');
        if (scrollTop && $content) {
            $content.scrollTop = scrollTop;
        }
    },
    components:{ AddCollection, RotateSquare5},
    methods: {


        //获取产品 初始化默认获取推荐分类下产品
        async product (key) {
            this.productList = [];
            const {token, productData} = this;
            this.productData.token = token;
            this.productData.index = 1;
            this.productData.key = key;
            this.isLoading = true;
            let res = await getProduct(productData);
            const {status, data} = res;
            this.productList = data.list;
            if (status == 0){
                this.isLoading = false;
                data.list.length < 20 && (this.finished = true);
            }

        },
        //加载更多
        async productLoad () {
            const {productData} = this;
            this.productData.index++;
            let res = await getProduct(productData);
            console.log(res);
            const {status, data} = res;
            if (status == 0 && data.list.length > 0) {
                this.productList.push(...data.list);
                this.loading = false;
            } else if (status == 0 && data.list.length < 20) {
                this.finished = true;
            }
        },

       
        //每次图片预加载完成后找出最后一张图片增加距底部高度
        preloaded () {
            setTimeout(() => {
                document.querySelector(`.vue-waterfall-easy>div:nth-child(${this.productList.length})`).style.marginBottom = '100px';
            })
        },

        //点击添加收藏操作
        onChangeCollection (id, index) {
            this.collectionModal = true;
            this.productId = id;
            this.productIndex = index;
            setTimeout(() => {
                this.$refs.collection.restTypes();
            })
        },

        //确认收藏后操作
        confirmCollection (data) {
            const {model, productIndex, isFav} = data;
            this.collectionModal = model;
            isFav && (this.productList[productIndex].favFlag = true);
        },
        
        //取消收藏
        async cancelCollection (id, index) {
            const {token} = this;
            let data = {token, id};
            let res = await removeFav(data);
            if (res.status == 0) {
                this.productList[index].favFlag = false;
            }
        },
        
        jump(id) {
            const {token, $router, activeKey, $route, position} = this;
            $router.push({name: 'productDetails', query:{token, id, position, vertical: $route.query.vertical}});
           
        },

        //选择筛选条件 
        selectScreen (e, index) {
            let target = e.target;
            if (target.className) {
                target.className = '';
                let val = this.screenLabels[index].val;
                for (let i in val) {
                    val[i] == target.innerText && val.splice(i, 1);
                };
                console.log(this.screenLabels[index]);
            } else {
                target.className = 'active';
                this.screenLabels[index].val.push(target.innerText)
            }
        },

        //确认筛选
        async confirmScreen () {
            const {screenLabels} = this;
            let txt = '';
            screenLabels.map(data => {
                data.val.length > 0 && (txt+=`${data.id}|${data.val.join(',')}$`);
            });
            txt = txt.substring(0, txt.lastIndexOf('$'));
            this.productData.index = 1;
            this.productData.labels = txt;
            let res = await getProduct(this.productData);
            console.log(res);
            const {list, total} = res.data;
            if (total != 0) {
                this.productList = list;
                this.screenModal = false;
            } else {
                this.$toast('没有找到方案，换个条件试试吧~');
            }
        },
        
    }
}
</script>

<style lang="less" src="@/style/index.less">

</style>